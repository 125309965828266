import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer>
    <div className="container flex flex-col justify-between pl-6">
      <div className="flex flex-wrap justify-between mb-4">
        <div className="w-full sm:w-1/2 md:w-1/4 flex flex-col">
          <h4>
            <Link
              className="no-underline text-blue-500 hover:text-blue-700"
              to="/porte-garage"
            >
              Portes de garage
            </Link>
          </h4>
          <ul className="ml-2">
            <li>
              <Link
                className="no-underline text-blue-500 hover:text-blue-700"
                to="/porte-garage/sectionnelles"
              >
                Portes sectionnelles
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-blue-500 hover:text-blue-700"
                to="/porte-garage/basculantes"
              >
                Portes basculantes
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-blue-500 hover:text-blue-700"
                to="/porte-garage/rollmatic"
              >
                Volet roulant
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 flex flex-col">
          <h4>
            <Link
              className="no-underline text-blue-500 hover:text-blue-700"
              to="/porte-entree"
            >
              Portes d'entrée
            </Link>
          </h4>
          <h4>
            <Link
              className="no-underline text-blue-500 hover:text-blue-700"
              to="/piece-detachee"
            >
              Pièces détachées
            </Link>
          </h4>
          <h4>
            <Link
              className="no-underline text-blue-500 hover:text-blue-700"
              to="/nos-realisations"
            >
              Réalisations
            </Link>
          </h4>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 flex flex-col">
          <h4>A propos de nous</h4>
          <ul className="ml-2">
            <li>
              <Link
                className="no-underline text-blue-500 hover:text-blue-700"
                to="/a-propos"
              >
                Qui sommes-nous ?
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-blue-500 hover:text-blue-700"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 flex flex-col">
          <h4>
            <Link
              className="no-underline text-blue-500 hover:text-blue-700"
              to="/offre-promotionelle"
            >
              Promotion
            </Link>
          </h4>
        </div>
      </div>
      <div>
        <p>© Portes Services {new Date().getFullYear()}</p>
      </div>
    </div>
  </footer>
)

export default Footer

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PDFJS from 'pdfjs-dist'

import { Loader } from '.'

async function createCanvas(url) {
  const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry')
  PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker

  const pdf = await PDFJS.getDocument(url).promise

  const page = await pdf.getPage(1)

  const scale = 1.5
  const viewport = page.getViewport({ scale })
  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d')
  canvas.height = viewport.height
  canvas.width = viewport.width
  await page.render({ canvasContext, viewport }).promise

  return canvas.toDataURL('image/jpeg')
}

class DownloadPdf extends Component {
  state = {
    page: null,
  }

  static propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)

    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    const url = this.props

    createCanvas(url).then(page => {
      if (this._isMounted) this.setState({ page })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { page } = this.state
    const { url, name, className, children } = this.props
    let containerClass = 'flex flex-wrap flex-col max-w-md text-center mt-6'

    if (className) {
      containerClass += ` ${className}`
    }

    return (
      <a className={containerClass} href={url} download={`${name}.pdf`}>
        {!page && <Loader />}
        <img
          className="max-w-xs"
          src={page}
          alt={name}
          style={{
            visibility: !page ? 'hidden' : 'visible',
            opacity: !page ? '0' : '1',
            transition: 'visibility 0s, opacity 0.5s linear',
          }}
        />
        <div className="btn btn-blue h-auto mt-6 ml-20 w-1/2">{children}</div>
      </a>
    )
  }
}

export default DownloadPdf

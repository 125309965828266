import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { Image } from '.'

const Inspiration = ({ className, title }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "inspiration.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const urlInspiration = 'https://www.hormann-inspiration.be/fr/'

  return (
    <div className={`w-full md:flex py-4 ${className}`}>
      <a href={urlInspiration} className="flex-1 my-auto">
        <Image fluid={data.image.childImageSharp.fluid} alt="inspiration" />
      </a>
      <div className="mx-2 flex-1 flex flex-col justify-between text-center pb-4">
        <h3 className="font-bold tracking-wider">
          {title || "Besoin d'inspiration ?"}
        </h3>
        <p className="tracking-wide text-lg">
          Venez consulter plus de 2000 réalisations de portes Hörmann
        </p>
        <a href={urlInspiration} className="btn btn-fill btn-blue self-center">
          Vers les références
        </a>
      </div>
    </div>
  )
}

Inspiration.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}

Inspiration.defaultProps = {
  title: '',
}

export default Inspiration

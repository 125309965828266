import React from 'react'

const Loader = () => (
  <div className="w-32 h-32 relative block z-50 mx-auto">
    <span className="text-blue-500 opacity-75 mx-auto w-full h-full">
      <i className="fas fa-circle-notch fa-spin fa-5x" />
    </span>
  </div>
)

export default Loader

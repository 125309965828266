import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ className, size, title, image, children, actions }) => {
  let titleClass = 'font-bold text-xl'
  let container = 'rounded overflow-hidden shadow-lg py-2'
  const renderActions = actions && <div className="px-6 py-4">{actions}</div>

  if (children) {
    titleClass += ' mb-2'
  }

  if (className) {
    container += ` ${className}`
  }

  container += ` ${size || 'max-w-xs lg:max-w-md'}`

  return (
    <div className={container}>
      {image}
      <div className="px-6 py-4">
        <div className={titleClass}>{title}</div>
        {children}
      </div>
      {renderActions}
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.node,
  children: PropTypes.node,
  actions: PropTypes.node,
}

export default Card

import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, Link } from 'gatsby'
import parse from 'html-react-parser'

import {} from '.'
import { createLocalLink } from '../utils'

const RecentPostsWidget = ({ className }) => {
  const {
    allWordpressPost: { nodes },
  } = useStaticQuery(graphql`
    {
      allWordpressPost(
        filter: { categories: { elemMatch: { name: { ne: "Site" } } } }
        limit: 5
      ) {
        nodes {
          id
          title
          link
        }
      }
    }
  `)

  return (
    <div className={className}>
      <h2>Posts récents</h2>
      <ul className="pl-8 pb-4">
        {nodes.map(({ link, title }, index) => (
          <li key={index} className="list-disc">
            <Link className="hover:text-blue-500" to={createLocalLink(link)}>
              {parse(title)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

RecentPostsWidget.propTypes = {
  className: PropTypes.string,
}

export default RecentPostsWidget

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const CategoriesWidget = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressCategory(filter: { name: { ne: "Site" } }) {
        nodes {
          name
          slug
          link
        }
      }
    }
  `)
  return (
    <div>
      <h2>Catégories</h2>
      <ul className="pl-8 pb-4">
        {data.allWordpressCategory.nodes.map((category, index) => (
          <li key={index} className="list-disc">
            <Link
              className="hover:text-blue-500"
              to={`/blog/category/${category.slug}`}
            >
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CategoriesWidget

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Loader } from '.'

class Lightbox extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const { url, images } = this.props
    const index = images.findIndex(img => img === url) || 0

    this.state = {
      image: index >= 0 ? images[index] : images[0],
      index,
      loader: false,
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp)
  }

  next = event => {
    event.preventDefault()
    const { images } = this.props
    const { index } = this.state
    const i = index === images.length - 1 ? 0 : index + 1

    this.setState({ index: i, image: images[i] })
  }

  previous = event => {
    event.preventDefault()
    const { images } = this.props
    const { index } = this.state
    const i = index === 0 ? images.length - 1 : index - 1

    this.setState({ index: i, image: images[i] })
  }

  onKeyUp = event => {
    const { close } = this.props

    if (event.key === 'Escape') {
      close(event)
    }
  }

  render() {
    const { loader, image } = this.state
    const { close } = this.props

    return (
      <div className="lightbox">
        <button className="lightbox__close" type="button" onClick={close} />
        <button className="lightbox__next" type="button" onClick={this.next} />
        <button
          className="lightbox__previous"
          type="button"
          onClick={this.previous}
        />
        <div className="lightbox__container">
          {loader ? (
            <Loader />
          ) : (
            <img
              className="lightbox__image"
              src={image.src}
              srcSet={image.srcSet}
              alt={image.originalName}
            />
          )}
        </div>
      </div>
    )
  }
}

export default Lightbox

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Toolbar from './Toolbar'
import SideDrawer from './SideDrawer/SideDrawer'
import BackDrop from './Backdrop'

class Sidebar extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => ({ sideDrawerOpen: !prevState.sideDrawerOpen }))
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render() {
    const { logo, title, children } = this.props
    const { sideDrawerOpen } = this.state
    let sideDrawer
    let hamburgerButtonShow = false
    let backdrop

    if (children != null) {
      sideDrawer = <SideDrawer show={sideDrawerOpen}>{children}</SideDrawer>
      hamburgerButtonShow = true
    }

    if (sideDrawerOpen) {
      backdrop = <BackDrop click={this.backdropClickHandler} />
    }

    return (
      <div className="h-full">
        <Toolbar
          logo={logo}
          title={title}
          show={hamburgerButtonShow}
          drawerClickHandler={this.drawerToggleClickHandler}
        >
          {children}
        </Toolbar>
        {sideDrawer}
        {backdrop}
      </div>
    )
  }
}

Sidebar.propTypes = {
  logo: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Sidebar

import React from 'react'
import PropTypes from 'prop-types'

const Article = ({ title, description, image, tags }) => {
  const ht = title

  return (
    <div className="flex justify-around border-b border-gray-300 py-4">
      <div className="w-1/5 self-center">{image}</div>
      <div className="w-4/5">
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="flex">
          {tags.map((tag, index) => {
            if (tag && tag.length > 0) {
              return (
                <p
                  key={index}
                  className="px-2 py-1 rounded-full bg-gray-400 text-gray-700 mx-2"
                >
                  {tag}
                </p>
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}

Article.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.node,
  tags: PropTypes.array,
}

export default Article

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import DrawerToggleButton from './SideDrawer/DrawerToggleButton'
import { Image } from '..'

const toolbar = ({ logo, title, drawerClickHandler, show, children }) => {
  let hamburgerButton
  let Logo
  let logoClass = ''

  if (logo) {
    Logo = (
      <div className="hidden sm:block self-center align-middle mr-6 w-10 h-10">
        <Image fixed={logo.fixed} />
      </div>
    )
  }

  if (show) {
    hamburgerButton = (
      <div className="inline xl:hidden">
        <DrawerToggleButton click={drawerClickHandler} />
      </div>
    )

    logoClass += 'ml-4 sm:ml-6 xl:ml-0'
  }

  return (
    <header className="top-0 left-0 right-0 shadow lg:shadow-lg w-full h-20">
      <nav className="flex items-center h-full px-4">
        {hamburgerButton}
        <div className={logoClass}>
          <Link className="no-underline flex flex-row" to="/">
            {Logo}
            <h1 className="font-bold text-3xl sm:text-4xl sm:tracking-wider text-blue-500 hover:text-blue-700">
              {title}
            </h1>
          </Link>
        </div>
        <div className="spacer" />
        <div>
          <ul className="hidden xl:flex">{children}</ul>
        </div>
      </nav>
    </header>
  )
}

toolbar.propTypes = {
  logo: PropTypes.object,
  title: PropTypes.string,
  drawerClickHandler: PropTypes.func,
  show: PropTypes.bool,
  children: PropTypes.any,
}

export default toolbar

import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

class Timer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.getTimeRemaining(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getTimeRemaining() {
    const { dateEnd } = this.props
    const duration = moment.duration(moment(dateEnd).diff(moment()))
    const { hours, minutes, seconds } = duration._data
    this.setState({
      days: Math.floor(duration.asDays()),
      hours,
      minutes,
      seconds,
    })
  }

  render() {
    const { className, description, dateEnd, children } = this.props
    const { months, days, hours, minutes, seconds } = this.state

    return (
      <p className={className}>
        {months > 0 && `${months} mois `}
        {days > 0 && `${days} jour${days > 1 ? "s" : ""} `}
        {hours > 0 && `${hours} heure${hours > 1 ? "s" : ""} `}
        {minutes > 0 && `${minutes} minute${minutes > 1 ? "s" : ""} `}
        {seconds > 0 && `${seconds} seconde${seconds > 1 ? "s" : ""}`}
      </p>
    )
  }
}

Timer.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  dateEnd: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Timer

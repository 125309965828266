/* eslint-disable react/no-danger */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import { Image } from '.'

const PostEntry = ({ post }) => {
  const { title, content, featureImage, path, date } = post

  return (
    <Fragment>
      <div className="flex justify-around p-6">
        <div className="w-full lg:w-1/6 flex flex-col justify-between">
          {featureImage && (
            <Image
              fluid={featureImage.localFile.childImageSharp.fluid}
              src={featureImage.sourceUrl}
              alt={featureImage.altText}
            />
          )}
          <p>Date : {new Date(date).toLocaleDateString()}</p>
        </div>
        <div className="w-full lg:w-5/6 pl-6">
          <h2>
            <Link className="text-blue-500 hover:text-blue-700" to={path}>
              {title}
            </Link>
          </h2>
          <div
            className="opacity-50"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
      <div className="bg-gray-400 w-11/12 mx-auto h-px" />
    </Fragment>
  )
}

PostEntry.propTypes = {
  post: PropTypes.object.isRequired,
}

export default PostEntry

export const query = graphql`
  fragment PostEntryFragment on wordpress__POST {
    id
    title
    path
    slug
    date
    content: excerpt
  }
`
// featureImage: featured_media {
//   localFile {
//     childImageSharp {
//       fluid {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
//   altText: alt_text
// }

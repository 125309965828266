import React from 'react'
import PropTypes from 'prop-types'

function backdrop({ click }) {
  function handleKeyDown(e) {
    if (e.keyCode === 27) {
      click()
    }
  }

  return (
    <div
      className="w-full h-screen bg-gray-300 opacity-75 fixed top-0 z-10"
      onClick={click}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      <button className="Backdrop__button" type="button" onClick={click}>
        <i className="fas fa-times fa-3x opacity-100 hover:text-red-700" />
      </button>
    </div>
  )
}

backdrop.propTypes = {
  click: PropTypes.func,
}

export default backdrop

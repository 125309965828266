import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { Sidebar } from '.'

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query Logo {
      file(
        relativeDirectory: { eq: "" }
        name: { eq: "logo_portes-services" }
      ) {
        logo: childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      allWordpressPost(
        filter: { categories: { elemMatch: { name: { ne: "Site" } } } }
      ) {
        totalCount
      }
    }
  `)

  return (
    <Sidebar logo={data.file.logo} title={siteTitle}>
      <li>
        <Link className="header__button" to="/porte-garage">
          Portes de garage
        </Link>
      </li>
      <li>
        <Link className="header__button" to="/porte-entree">
          Portes d'entrée
        </Link>
      </li>
      <li>
        <Link className="header__button" to="/porte-interieur">
          Portes d'intérieur
        </Link>
      </li>
      <li>
        <Link className="header__button" to="/motorisations">
          Motorisations
        </Link>
      </li>
      <li>
        <Link className="header__button" to="/piece-detachee">
          Pièces détachées
        </Link>
      </li>
      <li>
        <Link className="header__button" to="/nos-realisations">
          Réalisations
        </Link>
      </li>
      {data.allWordpressPost.totalCount > 0 && (
        <li>
          <Link className="header__button" to="/blog">
            Blog
          </Link>
        </li>
      )}
      <li>
        <Link className="header__button" to="/a-propos">
          A propos
        </Link>
      </li>
      <li>
        <Link className="header__button" to="/contact">
          Contact
        </Link>
      </li>
    </Sidebar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Portes Services`,
}

export default Header

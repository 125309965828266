import React from 'react'
import PropTypes from 'prop-types'

const sideDrawer = ({ show, children }) => (
  <div
    className="side-drawer"
    style={{
      transform: show ? 'translate(0%)' : 'translate(-100%)',
      transition: 'transform 0.25s ease-in',
    }}
  >
    <ul className="side-drawer__list">{children}</ul>
  </div>
)

sideDrawer.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
}

export default sideDrawer
